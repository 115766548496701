import { render, staticRenderFns } from "./Marquee.vue?vue&type=template&id=6076c79b&scoped=true&"
import script from "./Marquee.vue?vue&type=script&lang=js&"
export * from "./Marquee.vue?vue&type=script&lang=js&"
import style0 from "./Marquee.vue?vue&type=style&index=0&id=6076c79b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6076c79b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VAlert,VSheet})
