<template>
  <v-sheet class="pa-2 wrapper" elevation="0" :interval="10000" rounded>
    <v-alert
      class="mb-0"
      color="teal"
      dense
      text
    >
      <marquee behavior="alternate" direction="" @bounce="onBounce">{{ sentence }}</marquee>
    </v-alert>
  </v-sheet>
</template>

<script>

export default {
  props: {
    list: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      index: 0
    }
  },
  computed: {
    sentence () {
      return this.list[this.index]
    }
  },
  methods: {
    onBounce () {
      this.index = (this.index + 1) % this.list.length
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  box-shadow: 0 3px 11px 0 #e8eafc,0 3px 3px -2px hsla(0,0%,69.8%,.10196078431372549),0 1px 8px 0 hsla(0,0%,60.4%,.10196078431372549) !important;
}
</style>
